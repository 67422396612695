import React from "react"
import { Button, Tabs, Tab, Form } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import ReactMarkdown from "react-markdown"
import './ContactCard.scss';
const ContactCard = (props) => {
  return (
    <React.Fragment>
      <div class="contact-card">
        <h2>Contact Details</h2>
        <div className="contact-card-box">
          <span className="contact-card-title">Telephone Number:</span>
          <Link className="contact-info" href={"tel:" + props.contact?.Phone}>{props.contact?.Phone}</Link>
        </div>
        <div className="contact-card-box">
          <span className="contact-card-title">Email Address:</span>
          <a className="contact-info" onClick={props.executeScroll}>{props.contact?.Email}</a>
        </div>
        <div className="contact-card-box">
          <span className="contact-card-title">Office:</span>
          <address className="contact-info contact-info-address">
            <ReactMarkdown source={props.contact?.Address} allowDangerousHtml />
          </address>
        </div>
        <div className="contact-card-box">
          <span className="contact-card-title contact-card-media">Connect with us</span>
          <div className="contact-card-icon">
            {props.contact?.Facebook_Link &&
              <Link href={props.contact?.Facebook_Link} target="_blank" className="social-icon">
                <i className="icon-fb"></i>
              </Link>
            }
            {props.contact?.Linkedin_Link &&
              <Link href={props.contact?.Linkedin_Link} target="_blank" className="social-icon">
                <i className="icon-linkedln"></i>
              </Link>
            }
            {props.contact?.Instagram_Link &&
              <Link href={props.contact?.Instagram_Link} target="_blank" className="social-icon">
                <i className="icon-insta"></i>

              </Link>
            }
            {props.contact?.Twitter_Link &&
              <Link href={props.contact?.Twitter_Link} target="_blank" className="social-icon">
                <i className="icon-twitter"></i>
              </Link>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};
export default ContactCard;