import React from "react"
import { Button, Tab, Form ,Nav} from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'
import { Link } from "@StarberryUtils"
import CandidateDetailForm from "@Components/forms/candidate-detail";
import EmployerDetailForm from "@Components/forms/employer-detail";
import './Form.scss';
const ContactBanner = (props) => {
    return (
        <React.Fragment>
            <div class="form-block">
            <Tab.Container defaultActiveKey="first">
            <Nav className="nav-tabs nav-tabs-style">
            <Nav.Link eventKey="first"> <span className="d-none d-md-inline">I’m an</span> Employer</Nav.Link>
            <Nav.Link eventKey="second"> <span className="d-none d-md-inline">I’m a</span>  Candidate</Nav.Link>
                        
             </Nav>
             <Tab.Content>
                        <Tab.Pane eventKey="first" title="">
                            <div className="form-content">
                              <EmployerDetailForm person={props.person}/>
                                {/* <Form className="tab-form">
                                     <Form.Group>
                                    <Form.Control type="Text" placeholder="First Name" />
                                    </Form.Group>
                                    <Form.Group>
                                    <Form.Control type="Text" placeholder="Last Name" />
                                    </Form.Group>
                                      <Form.Group>
                                    <Form.Control type="Text" placeholder="Company Name" />
                                    </Form.Group>
                                      <Form.Group>
                                      <Form.Control as="select" className="select-input"> 
                                        <option>Company Industry</option>
                                        <option>Company Industry 1</option>
                                        <option>Company Industry 2</option>
                                      </Form.Control>
                                    <Form.Control type="Text" placeholder="Company Industry" />
                                      </Form.Group>
                                      <Form.Group>
                                    <Form.Control type="Text" placeholder="Telephone Number" />
                                      </Form.Group>
                                      <Form.Group>
                                      
                                    <Form.Control type="Text" placeholder="Email Address" />
                                    </Form.Group>
                                    <Form.Group>
                                    <Form.Control className="textarea_input" as="textarea" placeholder="How can we help?" />
                                    </Form.Group>
                                   <div className="btn-row">
                                   <Button type="button" className="btn">Submit Details</Button>
                                   </div>
                                    <Form.Text className="text-muted"> By clicking Submit Details, you agree to our Terms & Conditions and Privacy Policy. </Form.Text>
                                    </Form> */}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" className="Candidate">
                        <div className="form-content">
                                {/* <Form className="tab-form">
                                     <Form.Group>
                                    <Form.Control type="Text" placeholder="First Name" />
                                    </Form.Group>
                                    <Form.Group>
                                    <Form.Control type="Text" placeholder="Last Name" />
                                    </Form.Group>
                                     
                                      <Form.Group>
                                    <Form.Control type="Text" placeholder="Telephone Number" />
                                      </Form.Group>
                                      <Form.Group>
                                      
                                    <Form.Control type="Text" placeholder="Email Address" />
                                    </Form.Group>
                                    <Form.Group>
                                    <Form.Control className="textarea_input" as="textarea" placeholder="How can we help?" />
                                    </Form.Group>
                                   <div className="btn-row">
                                   <Button type="button" className="btn">Submit Details</Button>
                                   </div>
                                    <Form.Text className="text-muted"> By clicking Submit Details, you agree to our Terms & Conditions and Privacy Policy. </Form.Text>
                                    </Form> */}
                                    <CandidateDetailForm person={props.person} />
                            </div>
                        </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                  
            </div>
        </React.Fragment>
        )
};
export default ContactBanner;